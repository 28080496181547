'use strict';

Gri.module({
	name: 'contact-us-page',
	ieVersion: null,
	$el: $('.contact-us-page'),
	container: '.contact-us-page',
	fn: function () {

	$(".select2").each(function() {
		$(this).select2({
			minimumResultsForSearch: -1,
			placeholder: $(this).attr("placeholder").toString()
		});
	});

  }
});







var formSettings =
{
	elements : {
		phoneMask : $(".phone-mask"),
		tcMask : $(".tc-mask"),
		datePicker : $(".datepicker"),
		allFormElement : $(".input-container input, .textarea-container textarea, .select-container select")
  	},

	allFormElementEvents : "keyup focusout focusin blur change",

  	init: function(){
		formSettings.setInputMask();
		formSettings.setElementEvent();
		formSettings.setFormEvent();
		formSettings.setEBulten();
	},

	setEBulten: function(){

		$("#ebulten-button").click(function (e) {
			e.preventDefault();
			$("#frm2").validationEngine('validate', {
				autoHidePrompt: false,
				autoHideDelay: 0,
				fadeDuration: 0,
				scroll: false,
				validationEventTrigger: 'submit',
				onValidationComplete: function (form, status) {
					if (status) {
						e.preventDefault();
						var _data = $("#frm2").serialize();
						$("#ebulten-button").removeClass("error");
						$("#ebulten-button").addClass("loading");
						$.ajax({
							type: 'post',
							url: '/form/',
							data: _data,
							success: function (msg) {
								$("#ebulten-button").removeClass("loading");
								$("#ebulten-button").addClass("success");
								$("#frm2").find("#mail").val("mail kaydınız alınmıştır.");
								setTimeout(function(){
									document.forms['frm2'].reset();
									$("#ebulten-button").removeClass("success");
								}, 2000);
							}, error: function (msg) {
								$("#ebulten-button").removeClass("loading");
								$("#ebulten-button").addClass("error");
							}
						});
					} else {
						var msg = $("#frm2").find("#mail").attr("data-msg");
						$("#frm2").find("#mail").val(msg);
						$("#ebulten-button").addClass("error");
						formSettings.formSuccessControl("frm2");
					}
				}
			});
		});

	},

  	setElementEvent: function(){
		formSettings.elements.allFormElement.on(formSettings.allFormElementEvents, function() {
			formSettings.inputSuccessControl($(this));
		});
  	},

  	setFormEvent: function(){
    	$("#contact-form-button").click(function (e) {
      		e.preventDefault();
      		$("#frm1").validationEngine('validate', {
        		autoHidePrompt: false,
        		autoHideDelay: 0,
        		fadeDuration: 0,
        		scroll: false,
        		validationEventTrigger: 'submit',
        		onValidationComplete: function(form, status){
          			if(status) {
            			e.preventDefault();
						var _data = $("#frm1").serialize();
						$.ajax({type: 'post',
                            url: '/form/',
                            data: _data,
                            success: function (msg) {
								document.forms['frm1'].reset();
								console.log("Form Success!");
                            },error: function (msg) {
								console.log(msg.responseText);
                            }
                        });
          			} else {
            			formSettings.formSuccessControl("frm1");
          			}
				}
      		});
		});

		$(".input-container input, .textarea-container textarea, .select-container select").on("keyup focusout focusin blur change", function() {
			inputSuccessControl($(this));
		});

		function inputSuccessControl(inputParam){
            inputParam.validationEngine('validate');
            var hasError = ( inputParam.parent().find(".formError").length > 0 ) ? true : false;
            if (hasError) {
                inputParam.parent().removeClass("success");
            } else {
                inputParam.parent().addClass("success");
            }
        }
  	},

  	setInputMask: function(){
    	formSettings.elements.phoneMask.inputmask({
			mask: '+\\90 (999) 999 99 99',
			showMaskOnHover: false,
			autoUnmask: true
    	});

    	formSettings.elements.tcMask.inputmask({
      		mask: '99999999999',
      		showMaskOnHover: false,
      		autoUnmask: true
    	});
  	},

  	formSuccessControl: function(formID){
		var form = document.getElementById(formID);
		var collection = form.elements;
		for (var i = 0; i < collection.length; i++) {
			var currentFormItem = $("#" + collection[i].id);
			var isFormInput = currentFormItem.parent().hasClass("input-container") || currentFormItem.parent().hasClass("select-container") || currentFormItem.parent().hasClass("textarea-container");
			var hasError = ( currentFormItem.parent().find(".formError").length > 0 ) ? true : false;
			if(isFormInput) {
				if (hasError) {
					currentFormItem.parent().removeClass("success");
				} else {
					currentFormItem.parent().addClass("success");
				}
			}
		}
  	},

  	checkPhone : function(field, rules, i, options){
    	var phone = field.val();
    	var isPhone = (phone.length == 10) ? true : false;
    	if(!isPhone) {
        	return "* Geçersiz telefon numarası.";
    	}
  	},

  	checkTC: function(field, rules, i, options){
		if (!validateTC(field.val())) {
			return "* Geçersiz T.C. numarası.";
		}
		function validateTC(id) {
			id = String(id);
			if( ! id.match(/^[0-9]{11}$/) )
				return false;
			var pr1 = parseInt(id.substr(0,1));
			var pr2 = parseInt(id.substr(1,1));
			var pr3 = parseInt(id.substr(2,1));
			var pr4 = parseInt(id.substr(3,1));
			var pr5 = parseInt(id.substr(4,1));
			var pr6 = parseInt(id.substr(5,1));
			var pr7 = parseInt(id.substr(6,1));
			var pr8 = parseInt(id.substr(7,1));
			var pr9 = parseInt(id.substr(8,1));
			var pr10 = parseInt(id.substr(9,1));
			var pr11 = parseInt(id.substr(10,1));
			if( (pr1+pr3+pr5+pr7+pr9+pr2+pr4+pr6+pr8+pr10) % 10 != pr11 )
				return false;
			if( ( (pr1+pr3+pr5+pr7+pr9)*7 + (pr2+pr4+pr6+pr8)*9 ) % 10 != pr10 )
				return false;
			if( ( (pr1+pr3+pr5+pr7+pr9)*8) % 10 != pr11 )
				return false;
			return true;
		}
  	},

  	inputSuccessControl: function(inputParam){
      	inputParam.validationEngine('validate');
      	var hasError = ( inputParam.parent().find(".formError").length > 0 ) ? true : false;
      	if (hasError) {
        	inputParam.parent().removeClass("success");
      	} else {
        	inputParam.parent().addClass("success");
      	}
  	}

};

$(document).ready(formSettings.init);
